<template>
  <el-drawer
    title=""
    class="black-drawer"
    :size="'895px'"
    :visible.sync="drawerStrategy"
    :before-close="handleClose"
    :with-header="false"
  >
    <div class="strategy-form">
      <div class="drawer-header">
        {{['品牌策略管理', '业态策略管理', '主次力店策略管理'][drawerIndex]}}
        <i
          class="el-icon-close fr pointer"
          @click="handleClose"
        ></i>
      </div>
      <div
        class="drawer-wrap is-scroll-y"
        :style="'height:'+wrapHeight"
      >
        <div
          class="brand-decision-form"
          v-if="drawerIndex===0"
        >
          <ul
            v-for="(item,index) in formBrand"
            :key="item.id"
          >
            <li>
              <span class="requiredFields">品牌级次</span>
              <div class="native-input-solid disabled-sty">
                <input
                  type="text"
                  v-model="item.level"
                  disabled
                  placeholder="请输入"
                />
              </div>
            </li>
            <li>
              <span class="requiredFields">目标数量占比</span>
              <div class="native-input-solid">
                <input
                  @input="inputRules(index)"
                  type="text"
                  v-model="item.percentTargetNum"
                  :id="'brandValue-'+index"
                  autocomplete="off"
                  placeholder="请输入"
                />
                <span class="unit">%</span>
              </div>
            </li>
            <!-- <ul>
              <li v-for="(ele,i) in item.levelValue" :key="i">
                <span>{{['A', 'B', 'C', 'D'][i]}}级</span>
                <div class="native-input-solid">
                  <input
                    @input="inputRules(index, i)"
                    type="text"
                    autocomplete="off"
                    v-model="item.levelValue[i]"
                    placeholder="请输入" />
                  <span class="unit">%</span>
                </div>
              </li>
            </ul> -->
          </ul>
        </div>
        <div
          class="format-decision-form"
          v-if="drawerIndex===1"
        >
          <div
            class="form-item"
            v-for="(item,index) in formFormat"
            :key="item.key"
          >
            <div class="item-header">
              <span class="requiredFields">业态名称</span>
              <div class="native-input-solid">
                <el-select
                  class="date-picker-sty"
                  popper-class="w-block-select-down yellow-select-panel"
                  filterable
                  size="small"
                  placeholder="请选择"
                  :id="'name-'+item.key"
                  @change="handleCheckedFormatList"
                  v-model="item.name"
                >
                  <el-option
                    v-for="item in formatList"
                    :disabled="item.disabled"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <span
                v-if="index === formFormat.length-1"
                class="button-s-add"
                @click="handleFormatList('add', index)"
              ></span>
              <span
                :class="{'disabled-sty': formFormat.length===1}"
                class="button-s-del"
                @click="handleFormatList('del', index)"
              ></span>
              <i
                class="icon-show-hide fr pointer"
                :class="{'deg0': item.show}"
                @click="item.show = !item.show"
              ></i>
            </div>
            <div
              ref="formatContentBox"
              class="item-content"
              :style="!item.show?'margin-top:-'+boxHeight+'px':''"
            >
              <ul class="item-v1">
                <li>
                  <span class="requiredFields">规划面积</span>
                  <div class="native-input-solid">
                    <input
                      type="text"
                      @input="planAreaRules(0, index)"
                      v-model="item.planArea"
                      :id="'planArea-'+item.key"
                      autocomplete="off"
                      placeholder="请输入"
                    />
                    <span class="unit">㎡</span>
                  </div>
                </li>
                <li>
                  <span class="requiredFields">面积占比</span>
                  <div class="native-input-solid disabled-sty">
                    <input
                      type="text"
                      v-model="item.areaProportion"
                      autocomplete="off"
                      disabled
                      placeholder="请输入"
                    />
                    <span class="unit">%</span>
                  </div>
                </li>
                <li>
                  <span class="commonFields">租赁时限</span>
                  <div
                    class="native-input-solid-double"
                    id="doubleInputFormat"
                  >
                    <input
                      type="text"
                      @input="leasingLengthRules(0, 0, index)"
                      @focus="setStyle(1, 'doubleInputFormat')"
                      @blur="setStyle(0, 'doubleInputFormat')"
                      v-model="item.leasingStart"
                      autocomplete="off"
                      placeholder="请输入"
                    />
                    <span>年</span>
                    <span style="margin-left:16px">~</span>
                    <input
                      type="text"
                      @input="leasingLengthRules(0, 1, index)"
                      @focus="setStyle(1, 'doubleInputFormat')"
                      @blur="setStyle(0, 'doubleInputFormat')"
                      v-model="item.leasingEnd"
                      autocomplete="off"
                      placeholder="请输入"
                    />
                    <span>年</span>
                  </div>
                </li>
                <li>
                  <span class="commonFields">{{user.topuid === 6467 ? '物业' : '综合'}}管理费</span>
                  <div class="native-input-solid">
                    <input
                      @input="GARules(0, index)"
                      type="text"
                      v-model="item.generalAdministrative"
                      autocomplete="off"
                      placeholder="请输入"
                    />
                    <span class="unit">{{rentUnitList[decisionsInfo.rentUnit]}}</span>
                  </div>
                </li>
              </ul>
              <h3>业态租金</h3>
              <ul
                class="child-list"
                v-for="(ele,i) in item.rentList"
                :key="ele.decisionStepId"
              >
                <li>
                  <span class="requiredFields">阶段名称</span>
                  <div class="native-input-solid disabled-sty">
                    <input
                      type="text"
                      disabled
                      v-model="ele.name"
                      placeholder="请输入"
                    />
                  </div>
                </li>
                <li>
                  <span class="requiredFields">阶段时间</span>
                  <el-date-picker
                    class="date-picker-sty disabled-sty"
                    disabled
                    popper-class="black-el-date-picker"
                    type="daterange"
                    range-separator="-"
                    v-model="ele.phaseTime"
                    placeholder="选择日期"
                    format="yyyy/MM/dd"
                    value-format="yyyy/MM/dd"
                    :ref="`phaseTime-${index}-${i}`"
                    @change="handlePhaseTime(0, index,i)"
                  >
                  </el-date-picker>
                </li>
                <li>
                  <span class="commonFields">策略租金</span>
                  <div class="native-input-solid">
                    <input
                      type="text"
                      v-model="ele.decisionRent"
                      placeholder="请输入"
                      :id="`decisionRent-${index}-${i}`"
                      autocomplete="off"
                      @blur="blurRentRules(0, index,i)"
                      @focus="focusRentRules(0, index,i)"
                      @input="decisionRentRules(0, index,i)"
                    />
                    <span class="unit">{{rentUnitList[decisionsInfo.rentUnit]}}</span>
                  </div>
                </li>
                <li>
                  <span class="commonFields">免租时长</span>
                  <div class="native-input-solid">
                    <input
                      type="text"
                      @input="handlePhaseTime(0, index,i)"
                      onkeyup="value=value.replace(/[^\d]/g,'') "
                      v-model="ele.freeTime"
                      autocomplete="off"
                      :id="`freeTime-${index}-${i}`"
                      placeholder="请输入"
                    />
                    <span class="unit">天</span>
                  </div>
                </li>
                <li>
                  <span class="commonFields">租金递增率</span>
                  <div
                    class="native-input-solid"
                    :class="{'disabled-sty': i===0}"
                  >
                    <input
                      type="text"
                      :disabled="i===0"
                      @input="rentGrowthRules(0, index, i)"
                      @focus="focusRentRules(0, index,i)"
                      v-model="ele.rentGrowth"
                      placeholder="请输入"
                    />
                    <span class="unit">%</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="format-decision-form"
          v-if="drawerIndex===2"
        >
          <div
            class="form-item"
            v-for="(item,index) in formStore"
            :key="item.name"
          >
            <div class="item-header">
              <span class="requiredFields">主次力店</span>
              <div class="native-input-solid disabled-sty">
                <input
                  type="text"
                  v-model="item.name"
                  disabled
                  placeholder="请输入"
                />
              </div>
              <i
                class="icon-show-hide fr pointer"
                :class="{'deg0': item.show}"
                @click="item.show = !item.show"
              ></i>
            </div>
            <div
              ref="storeContentBox"
              class="item-content"
              :style="!item.show?'margin-top:-'+boxHeight+'px':''"
            >
              <ul class="item-v1">
                <li>
                  <span class="requiredFields">规划面积</span>
                  <div class="native-input-solid">
                    <input
                      type="text"
                      @input="planAreaRules(1, index)"
                      :id="'store-planArea-'+index"
                      autocomplete="off"
                      v-model="item.planArea"
                      placeholder="请输入"
                    />
                    <span class="unit">㎡</span>
                  </div>
                </li>
                <li>
                  <span class="requiredFields">面积占比</span>
                  <div class="native-input-solid disabled-sty">
                    <input
                      type="text"
                      v-model="item.areaProportion"
                      disabled
                      placeholder="请输入"
                    />
                    <span class="unit">%</span>
                  </div>
                </li>
                <li>
                  <span class="commonFields">租赁时限</span>
                  <div
                    class="native-input-solid-double"
                    id="doubleInputStore"
                  >
                    <input
                      type="text"
                      @input="leasingLengthRules(1, 0, index)"
                      @focus="setStyle(1, 'doubleInputStore')"
                      @blur="setStyle(0, 'doubleInputStore')"
                      v-model="item.leasingStart"
                      autocomplete="off"
                      placeholder="请输入"
                    />
                    <span>年</span>
                    <span style="margin-left:16px">~</span>
                    <input
                      type="text"
                      @input="leasingLengthRules(1, 1, index)"
                      @focus="setStyle(1, 'doubleInputStore')"
                      @blur="setStyle(0, 'doubleInputStore')"
                      v-model="item.leasingEnd"
                      autocomplete="off"
                      placeholder="请输入"
                    />
                    <span>年</span>
                  </div>
                </li>
                <li>
                  <span class="commonFields">{{user.topuid === 6467 ? '物业' : '综合'}}管理费</span>
                  <div class="native-input-solid">
                    <input
                      @input="GARules(1, index)"
                      type="text"
                      v-model="item.generalAdministrative"
                      autocomplete="off"
                      placeholder="请输入"
                    />
                    <span class="unit">{{rentUnitList[decisionsInfo.rentUnit]}}</span>
                  </div>
                </li>
              </ul>
              <h3>租金策略</h3>
              <ul
                class="child-list"
                v-for="(ele,i) in item.rentList"
                :key="i"
              >
                <li>
                  <span class="requiredFields">阶段名称</span>
                  <div class="native-input-solid">
                    <input
                      class="disabled-sty"
                      disabled
                      type="text"
                      autocomplete="off"
                      v-model="ele.name"
                      placeholder="请输入"
                    />
                  </div>
                </li>
                <li class="w-input-sty">
                  <span class="requiredFields">阶段时间</span>
                  <el-date-picker
                    class="date-picker-sty disabled-sty"
                    disabled
                    popper-class="black-el-date-picker"
                    type="daterange"
                    range-separator="-"
                    v-model="ele.phaseTime"
                    placeholder="选择日期"
                    format="yyyy/MM/dd"
                    value-format="yyyy/MM/dd"
                    :ref="`phaseTime-${index}-${i}`"
                    @change="handlePhaseTime(1, index,i)"
                  >
                  </el-date-picker>
                </li>
                <li>
                  <span class="commonFields">策略租金</span>
                  <div class="native-input-solid">
                    <input
                      type="text"
                      v-model="ele.decisionRent"
                      placeholder="请输入"
                      autocomplete="off"
                      :id="`decisionRent-${index}-${i}`"
                      @focus="focusRentRules(1, index,i)"
                      @input="decisionRentRules(1, index,i)"
                    />
                    <span class="unit">{{rentUnitList[decisionsInfo.rentUnit]}}</span>
                  </div>
                </li>
                <li>
                  <span class="commonFields">免租时长</span>
                  <div class="native-input-solid">
                    <input
                      type="text"
                      onkeyup="value=value.replace(/[^\d]/g,'') "
                      @input="handlePhaseTime(1, index,i)"
                      v-model="ele.freeTime"
                      autocomplete="off"
                      :id="`freeTime-${index}-${i}`"
                      placeholder="请输入"
                    />
                    <span class="unit">天</span>
                  </div>
                </li>
                <li>
                  <span class="commonFields">租金递增率</span>
                  <div
                    class="native-input-solid"
                    :class="{'disabled-sty': i===0}"
                  >
                    <input
                      type="text"
                      :disabled="i===0"
                      v-model="ele.rentGrowth"
                      @focus="focusRentRules(1, index,i)"
                      @input="rentGrowthRules(1, index, i)"
                      placeholder="请输入"
                    />
                    <span class="unit">%</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="drawer-footer">
        <span
          @click="submitForm"
          class="button-basic button-primary"
          :class="{'disabled-sty': notYet}"
        >保存</span>
        <span
          @click="handleClose"
          class="button-basic button-low"
        >取消</span>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { debounce } from '@/utils'
import dayjs from 'dayjs'
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  name: 'strategyForm',
  data () {
    return {
      boxHeight: 0,
      notYet: true,
      drawerStrategy: false,
      formatList: [],
      formBrand: {},
      formFormat: {},
      formStore: {},
      projectGrossArea: 0,
      rentUnitList: ['', '元/日/㎡', '元/月/㎡'],
      formFormatItemKey: 0,
      brandCardList: [],
      drawerIndex: 0,
      decisionsInfo: null,
      drawerType: 0
    }
  },
  props: {
    decisionId: {
      type: Number,
      default: 0
    }
  },
  mounted () {
  },
  computed: {
    wrapHeight: () => {
      return document.documentElement.clientHeight - 145 + 'px'
    },
    ...mapGetters({
      user: 'user'
    })
  },
  methods: {
    setStyle (flag, keyword) {
      if (flag) {
        document.getElementById(keyword).classList.add('is-active')
      } else {
        document.getElementById(keyword).classList.remove('is-active')
      }
    },
    checkForm (type) {
      if (type === 0) {
        let res = 0
        let hasZero = false
        for (let i = 0; i < this.formBrand.length; i++) {
          const item = this.formBrand[i]
          res += Number(item.percentTargetNum)
          if (Number(item.percentTargetNum) === 0) {
            hasZero = true
          }
          if (!item.percentTargetNum && Number(item.percentTargetNum) !== 0) {
            this.notYet = true
            return
          }
        }
        if (hasZero) {
          console.log('res', res)
          if (res === 100) {
            this.notYet = false
          } else {
            this.notYet = true
          }
        } else {
          this.notYet = false
        }
      }
      if (type === 1) {
        for (let i = 0; i < this.formFormat.length; i++) {
          const item = this.formFormat[i]
          if (!item.name) {
            this.notYet = true
            return false
          }
          if (!item.planArea) {
            this.notYet = true
            return false
          }
          this.notYet = false
        }
        return true
      }
      if (type === 2) {
        for (let i = 0; i < this.formStore.length; i++) {
          const item = this.formStore[i]
          if (!item.planArea) {
            this.notYet = true
            return
          }
          this.notYet = false
        }
      }
    },
    getDataFormatList () {
      this.axios.post(api.getListIndustry1, {}).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          this.formatList = resData.map(item => {
            const tempObj = {
              disabled: false,
              label: item.name,
              value: item.id
            }
            return tempObj
          })
          // this.resetFormFormat()
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleCheckedFormatList (element) {
      for (let i = 0; i < this.formFormat.length; i++) { // 选择品牌级次自动展开表单
        const item = this.formFormat[i]
        if (item.name === element) {
          item.show = true
        }
      }
      // 以下代码为设置已选业态项禁选
      const checkedList = []
      for (let i = 0; i < this.formFormat.length; i++) {
        const item = this.formFormat[i]
        checkedList.push(item.name)
      }
      for (let i = 0; i < this.formatList.length; i++) {
        const item = this.formatList[i]
        if (checkedList.indexOf(item.value) !== -1) {
          item.disabled = true
        } else {
          item.disabled = false
        }
      }
    },
    unfinishedMsg () {
      this.$message({
        type: 'warning',
        message: '表单尚未填写完整'
      })
    },
    getDataFormatForm () {
      const params = {
        decisionId: this.decisionId
      }
      this.axios.get(api.handleFormatDecisionsForm, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.formFormat = resData.industryStrategies.map((item, i) => {
            const tempObj = {}
            if (i === 0) {
              tempObj.show = true
            } else {
              tempObj.show = false
            }
            tempObj.key = item.industryStrategyId
            tempObj.name = item.industry
            tempObj.industryStrategyId = item.industryStrategyId
            tempObj.planArea = item.planArea
            tempObj.areaProportion = item.areaRate
            tempObj.leasingStart = item.rentMinYear
            tempObj.leasingEnd = item.rentMaxYear
            tempObj.generalAdministrative = item.managementCost || 0
            tempObj.rentList = item.industryStrategyRents.map((item1) => {
              const tempObj = {
                decisionStepId: item1.decisionStepId,
                name: item1.decisionStepName,
                industryStrategyRentId: item1.industryStrategyRentId,
                freeTime: item1.rentFreeTime,
                rentGrowth: item1.rentIncreasingRate || 0,
                decisionRent: item1.standardRent,
                phaseTime: [dayjs(item1.stepStartDate).format('YYYY/MM/DD'), dayjs(item1.stepEndDate).format('YYYY/MM/DD')]
              }
              return tempObj
            })
            return tempObj
          })
          this.boxHeight = this.$refs.formatContentBox[0].clientHeight
          this.checkForm(1)
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataStoreForm () {
      const params = {
        decisionId: this.decisionId
      }
      this.axios.get(api.handleStoreForm, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          for (let i = 0; i < resData.berthStrategies.length; i++) {
            const item = resData.berthStrategies[i]
            const ele = this.formStore[i]
            ele.areaProportion = item.areaRate
            ele.berthStrategyId = item.berthStrategyId
            for (let j = 0; j < item.berthStrategyRents.length; j++) {
              const item1 = item.berthStrategyRents[j]
              const ele1 = ele.rentList[j]
              ele1.berthStrategyRentId = item1.berthStrategyRentId
              ele1.decisionStepId = item1.decisionStepId
              ele1.name = item1.decisionStepName
              ele1.freeTime = item1.rentFreeTime
              ele1.rentGrowth = item1.rentIncreasingRate || 0
              ele1.decisionRent = item1.standardRent
              ele1.phaseTime = [dayjs(item1.stepStartDate).format('YYYY/MM/DD'), dayjs(item1.stepEndDate).format('YYYY/MM/DD')]
            }
            ele.generalAdministrative = item.managementCost || 0
            ele.planArea = item.planArea
            ele.leasingEnd = item.rentMaxYear
            ele.leasingStart = item.rentMinYear
          }
          this.boxHeight = this.$refs.storeContentBox[0].clientHeight
          this.checkForm(2)
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleFormatList (key, index) {
      if (key === 'add') {
        if (!this.checkForm(1)) {
          this.$message({
            type: 'warning',
            message: '请完善当前业态策略配置'
          })
          return false
        }
        const obj = {
          show: false,
          key: this.formFormatItemKey,
          name: '',
          planArea: '',
          areaProportion: '',
          leasingStart: '',
          leasingEnd: '',
          generalAdministrative: '',
          rentList: [
            // {
            //   name: '',
            //   date: '',
            //   decisionRent: '',
            //   freeTime: '',
            //   rentGrowth: ''
            // }
          ]
        }
        obj.rentList = this.decisionsInfo.steps.map((item, index) => {
          const tempObj = {
            decisionRent: '',
            freeTime: '',
            rentGrowth: index ? '' : 0
          }
          tempObj.decisionStepId = item.decisionStepId
          tempObj.name = item.decisionStepName
          tempObj.phaseTime = [dayjs(item.stepStartDate).format('YYYY/MM/DD'), dayjs(item.stepEndDate).format('YYYY/MM/DD')]
          return tempObj
        })
        this.formFormat.splice(index + 1, 0, obj)
        this.formFormatItemKey++
        this.notYet = true
      }
      if (key === 'del') {
        this.formFormat.splice(index, 1)
        this.handleCheckedFormatList()
        this.checkForm(1)
      }
    },
    hasDot (num) {
      console.log('num', num)
      if (!isNaN(num)) {
        return ((num + '').indexOf('.') !== -1) ? num.toFixed(2) : num
      }
    },
    rentGrowthRules: debounce(function (key, index, i) {
      let value = ''
      if (key === 0) {
        if (this.formFormat[index].rentList[i].rentGrowth === '') {
          this.formFormat[index].rentList[i].decisionRent = ''
          this.decisionRentRules(key, index, i)
          return
        }
        value = Number(this.formFormat[index].rentList[i].rentGrowth)
        console.log(value)
        if (!value) {
          this.formFormat[index].rentList[i].decisionRent = this.formFormat[index].rentList[i - 1].decisionRent
          this.decisionRentRules(key, index, i)
          return
        }
      }
      if (key === 1) {
        if (this.formStore[index].rentList[i].rentGrowth === '') {
          this.formStore[index].rentList[i].decisionRent = ''
          this.decisionRentRules(key, index, i)
          return
        }
        value = Number(this.formStore[index].rentList[i].rentGrowth)
        console.log(value)
        if (!value) {
          this.formStore[index].rentList[i].decisionRent = this.formStore[index].rentList[i - 1].decisionRent
          this.decisionRentRules(key, index, i)
          return
        }
      }
      if (value > 99999.99) {
        this.$message({
          type: 'warning',
          message: '该项取值不大于99999.99，允许小数点后两位'
        })
      }
      value = value.toString().match(/^\d{0,5}(?:\.\d{0,2})?/)[0] // 输入限制
      if (key === 0) {
        this.formFormat[index].rentList[i].rentGrowth = value
        const a0 = Number(this.formFormat[index].rentList[i - 1].decisionRent)
        const b1 = Number(value)
        this.formFormat[index].rentList[i].decisionRent = this.hasDot((a0 * b1) / 100 + a0)
      }
      if (key === 1) {
        this.formStore[index].rentList[i].rentGrowth = value
        const a0 = Number(this.formStore[index].rentList[i - 1].decisionRent)
        const b1 = Number(value)
        this.formStore[index].rentList[i].decisionRent = this.hasDot((a0 * b1) / 100 + a0)
      }
      this.decisionRentRules(key, index, i)
    }, 500),
    blurRentRules (key, index, i) {
      let value = ''
      if (key === 0) {
        value = this.formFormat[index].rentList[i].decisionRent
        if (Number(value) === 0) {
          this.formFormat[index].rentList[i].decisionRent = ''
        }
      }
      if (key === 1) {
        value = this.formStore[index].rentList[i].decisionRent
        if (Number(value) === 0) {
          this.formStore[index].rentList[i].decisionRent = ''
        }
      }
    },
    focusRentRules (key, index, i) {
      if (i !== 0) {
        let value = '' // 上一阶段租金
        if (key === 0) {
          value = this.formFormat[index].rentList[i - 1].decisionRent
        }
        if (key === 1) {
          value = this.formStore[index].rentList[i - 1].decisionRent
        }
        if (!value) {
          document.getElementById(`decisionRent-${index}-${i - 1}`).focus()
          this.$message({
            type: 'warning',
            message: '请先填写上一阶段策略租金'
          })
        }
      }
    },
    handlePhaseTime: debounce(function (key, index, i) {
      let value = '' // 阶段时间
      let value1 = '' // 免租时长
      if (key === 0) { // key: 0 业态 1 主次力店
        value = this.formFormat[index].rentList[i].phaseTime
        value1 = this.formFormat[index].rentList[i].freeTime
      }
      if (key === 1) { // key: 0 业态 1 主次力店
        value = this.formStore[index].rentList[i].phaseTime
        value1 = this.formStore[index].rentList[i].freeTime
      }
      if (value && value1) {
        if (typeof value === 'object') {
          const len = dayjs(value[1]).diff(value[0], 'day')
          if (value1 > len + 1) {
            this.$message({
              type: 'warning',
              message: '免租时长不可超过阶段时间时长，请重新输入免租时长'
            })
            if (key === 0) { // key: 0 业态 1 主次力店
              this.formFormat[index].rentList[i].freeTime = ''
            }
            if (key === 1) { // key: 0 业态 1 主次力店
              this.formStore[index].rentList[i].freeTime = ''
            }
            document.getElementById(`freeTime-${index}-${i}`).focus()
          } else {
            this.checkForm(1)
            this.checkForm(2)
          }
        }
      }
    }, 300),
    decisionRentRules: debounce(function (key, index, i) {
      // key: 0业态 1主次力店
      // index: 业态：各业态索引；主次力店：主力店，次力店，普通店
      // i: 阶段索引
      // 公式 (当前阶段策略租金-上一阶段策略租金)/上一阶段策略租金
      let value = '' // 当前阶段策略租金
      if (key === 0) { // key: 0业态 1主次力店
        value = this.formFormat[index].rentList[i].decisionRent
      }
      if (key === 1) { // key: 0业态 1主次力店
        value = this.formStore[index].rentList[i].decisionRent
      }
      if (parseInt(value) === 0) {
        this.$message({
          type: 'warning',
          message: '该项取值不为0'
        })
      }
      if (parseInt(value) > 99999.99) {
        this.$message({
          type: 'warning',
          message: '该项取值不大于99999.99，允许小数点后两位'
        })
      }
      value = value.toString().match(/^\d{0,5}(?:\.\d{0,2})?/)[0] // 输入限制
      if (key === 0) {
        this.formFormat[index].rentList[i].decisionRent = value // 当前
        const value1 = i !== 0 ? this.formFormat[index].rentList[i - 1].decisionRent : '' // 上一阶段策略租金
        const value2 = i !== this.formFormat[index].rentList.length - 1 ? this.formFormat[index].rentList[i + 1].decisionRent : '' // 下一阶段策略租金
        if (i === 0) { // 第一个涉及下一阶段
          if (Number(value) && Number(value2)) {
            this.formFormat[index].rentList[i + 1].rentGrowth = this.hasDot(((value2 - value) / value) * 100)
          } else {
            this.formFormat[index].rentList[i + 1].rentGrowth = 0
          }
        }
        if (i > 0 && i < this.formFormat[index].rentList.length) { // 中间部分涉及当前和下一阶段
          if (Number(value)) { // 如果当前租金存在
            if (Number(value1)) { // 如果上一阶段租金存在
              this.formFormat[index].rentList[i].rentGrowth = this.hasDot(((value - value1) / value1) * 100)
            } else {
              this.formFormat[index].rentList[i].rentGrowth = 0
            }
            if (Number(value2)) {
              this.formFormat[index].rentList[i + 1].rentGrowth = this.hasDot(((value2 - value) / value) * 100)
            } else {
              this.formFormat[index].rentList[i + 1].rentGrowth = 0
            }
          } else {
            this.formFormat[index].rentList[i].rentGrowth = ''
            this.formFormat[index].rentList[i + 1].rentGrowth = ''
          }
        }
        if (i === this.formFormat[index].rentList.length - 1) { // 最后一个涉及本身
          if (Number(value) && Number(value1)) {
            this.formFormat[index].rentList[i + 1].rentGrowth = this.hasDot(((value - value1) / value1) * 100)
          } else {
            this.formFormat[index].rentList[i + 1].rentGrowth = 0
          }
        }
      }
      if (key === 1) {
        this.formStore[index].rentList[i].decisionRent = value // 当前
        const value1 = i !== 0 ? this.formStore[index].rentList[i - 1].decisionRent : '' // 上一阶段策略租金
        const value2 = i !== this.formStore[index].rentList.length - 1 ? this.formStore[index].rentList[i + 1].decisionRent : '' // 下一阶段策略租金
        if (i === 0) { // 第一个涉及下一阶段
          if (Number(value) && Number(value2)) {
            this.formStore[index].rentList[i + 1].rentGrowth = this.hasDot(((value2 - value) / value) * 100)
          } else {
            this.formStore[index].rentList[i + 1].rentGrowth = 0
          }
        }
        if (i > 0 && i < this.formStore[index].rentList.length) { // 中间部分涉及当前和下一阶段
          if (Number(value)) {
            if (Number(value1)) {
              this.formStore[index].rentList[i].rentGrowth = this.hasDot(((value - value1) / value1) * 100)
            } else {
              this.formStore[index].rentList[i].rentGrowth = 0
            }
            if (Number(value2)) {
              this.formStore[index].rentList[i + 1].rentGrowth = this.hasDot(((value2 - value) / value) * 100)
            } else {
              this.formStore[index].rentList[i + 1].rentGrowth = 0
            }
          } else {
            this.formStore[index].rentList[i].rentGrowth = ''
            this.formStore[index].rentList[i + 1].rentGrowth = ''
          }
        }
        if (i === this.formStore[index].rentList.length - 1) { // 最后一个涉及本身
          if (Number(value) && Number(value1)) {
            this.formStore[index].rentList[i + 1].rentGrowth = this.hasDot(((value - value1) / value1) * 100)
          } else {
            this.formStore[index].rentList[i + 1].rentGrowth = 0
          }
        }
      }
    }, 300),
    GARules: debounce(function (key, index) {
      let value = 0
      if (key === 0) { // key: 0 业态 1 主次力店
        value = this.formFormat[index].generalAdministrative
      }
      if (key === 1) { // key: 0 业态 1 主次力店
        value = this.formStore[index].generalAdministrative
      }
      if (parseInt(value) > 99999.99) {
        this.$message({
          type: 'warning',
          message: '该项取值不大于99999.99，允许小数点后两位'
        })
      }
      value = value.toString().match(/^\d{0,5}(?:\.\d{0,2})?/)[0]
      if (key === 0) {
        this.formFormat[index].generalAdministrative = value
      }
      if (key === 1) {
        this.formStore[index].generalAdministrative = value
      }
    }, 300),
    planAreaRules: debounce(function (key, index) {
      let value = 0
      if (key === 0) { // key: 0 业态 1 主次力店
        value = this.formFormat[index].planArea
      }
      if (key === 1) {
        value = this.formStore[index].planArea
      }
      if (value > 999999) {
        this.$message({
          type: 'warning',
          message: `该项取值范围：小于一百万，且小于项目总建筑面积：${this.projectGrossArea}㎡，允许小数点后两位`
        })
      }
      value = value.toString().match(/^\d{0,6}(?:\.\d{0,2})?/)[0]
      if (key === 0) {
        this.formFormat[index].planArea = value
        this.formFormat[index].areaProportion = (value / this.projectGrossArea * 100).toString().match(/^\d{0,3}(?:\.\d{0,2})?/)[0]
        this.checkForm(1)
      }
      if (key === 1) {
        this.formStore[index].planArea = value
        this.formStore[index].areaProportion = (value / this.projectGrossArea * 100).toString().match(/^\d{0,3}(?:\.\d{0,2})?/)[0]
        this.checkForm(2)
      }
    }, 300),
    leasingLengthRules: debounce(function (key, order, index) {
      let value1 = ''
      let value2 = ''
      if (key === 0) {
        value1 = this.formFormat[index].leasingStart.toString().match(/^\d{0,2}/)[0]
        value2 = this.formFormat[index].leasingEnd.toString().match(/^\d{0,2}/)[0]
      }
      if (key === 1) {
        value1 = this.formStore[index].leasingStart.toString().match(/^\d{0,2}/)[0]
        value2 = this.formStore[index].leasingEnd.toString().match(/^\d{0,2}/)[0]
      }
      if (parseInt(value1) > parseInt(value2) && value1 && value2) {
        this.$message({
          type: 'warning',
          message: '后面的单元格需大于等于第一个单元格'
        })
        if (order === 0) {
          value1 = ''
        }
        if (order === 1) {
          value2 = ''
        }
      }
      if (key === 0) {
        this.formFormat[index].leasingStart = value1
        this.formFormat[index].leasingEnd = value2
      }
      if (key === 1) {
        this.formStore[index].leasingStart = value1
        this.formStore[index].leasingEnd = value2
      }
    }, 900),
    inputRules: debounce(function (index, i) {
      let value = ''
      if (i !== undefined) {
        // value = this.formBrand[index].levelValue[i]
      } else {
        value = this.formBrand[index].percentTargetNum
      }
      if (i === undefined) {
        this.checkForm(0)
      }
      if (value.length === 0) { // 为空不往下执行
        return
      }
      value = value.toString().match(/^\d{0,3}(?:\.\d{0,2})?/)[0]
      if (value > 100 || value < 0) {
        this.$message({
          type: 'warning',
          message: '该项取值范围：大于等于0%，且小于等于100%，允许小数点后两位'
        })
        value = 100
      }
      if (i !== undefined) {
        // this.$set(this.formBrand[index].levelValue, i, value)
      } else {
        this.formBrand[index].percentTargetNum = value
      }
    }, 300),
    handleSubmit (data) {
      console.log('data', data)
    },
    resetFormStore () {
      this.formStore = []
      this.formStore = [
        {
          show: true,
          name: '主力店',
          planArea: '',
          areaProportion: '',
          leasingStart: '',
          leasingEnd: '',
          generalAdministrative: 0,
          rentList: this.decisionsInfo.steps.map(item => {
            const tempObj = {
              decisionRent: '',
              freeTime: '',
              rentGrowth: 0
            }
            tempObj.decisionStepId = item.decisionStepId
            tempObj.name = item.decisionStepName
            tempObj.phaseTime = []
            tempObj.phaseTime.push(dayjs(item.stepStartDate).format('YYYY/MM/DD'))
            tempObj.phaseTime.push(dayjs(item.stepEndDate).format('YYYY/MM/DD'))
            return tempObj
          })
        },
        {
          show: true,
          name: '次主力店',
          planArea: '',
          areaProportion: '',
          leasingStart: '',
          leasingEnd: '',
          generalAdministrative: 0,
          rentList: this.decisionsInfo.steps.map(item => {
            const tempObj = {
              decisionRent: '',
              freeTime: '',
              rentGrowth: 0
            }
            tempObj.decisionStepId = item.decisionStepId
            tempObj.name = item.decisionStepName
            tempObj.phaseTime = []
            tempObj.phaseTime.push(dayjs(item.stepStartDate).format('YYYY/MM/DD'))
            tempObj.phaseTime.push(dayjs(item.stepEndDate).format('YYYY/MM/DD'))
            return tempObj
          })
        }
      ]
      console.log('formStore11111', this.formStore)
    },
    resetFormFormat () {
      for (let i = 0; i < this.formatList.length; i++) {
        const item = this.formatList[i]
        item.disabled = false
      }
      this.formFormatItemKey = 0
      this.formFormat = []
      const obj = {
        show: false,
        key: this.formFormatItemKey,
        name: '',
        planArea: '',
        areaProportion: '',
        leasingStart: '',
        leasingEnd: '',
        generalAdministrative: 0,
        rentList: [
        ]
      }
      obj.rentList = this.decisionsInfo.steps.map(item => {
        const tempObj = {
          decisionRent: '',
          freeTime: '',
          rentGrowth: 0
        }
        tempObj.decisionStepId = item.decisionStepId
        tempObj.name = item.decisionStepName
        tempObj.phaseTime = [dayjs(item.stepStartDate).format('YYYY/MM/DD'), dayjs(item.stepEndDate).format('YYYY/MM/DD')]
        return tempObj
      })
      this.formFormat.push(obj)
      this.formFormatItemKey++
    },
    submitForm () {
      if (this.drawerIndex === 0) {
        this.submitFormBrand()
      }
      if (this.drawerIndex === 1) {
        this.submitFormFormat()
      }
      if (this.drawerIndex === 2) {
        this.submitFormStore()
      }
    },
    submitFormBrand () {
      const params = {
        // brandLevels: [ // 品牌级次列表
        //   {
        //     brandGradeType: [ // 品牌级次等级（1:A级，2:B级，3:C级，4:D级）
        //       0
        //     ],
        //     brandGradeValue: [ // 品牌级次等级目标数量占比/数量 ,
        //       0
        //     ],
        //     brandLevelType: 0, // 品牌级次（1:标杆，2:标准，3:非标） ,
        //     brandLevelValue: 0 // 品牌级次目标数量占比/数量
        //   }
        // ],
        decisionId: this.decisionId // 租赁决策ID
      }
      let sum = 0
      for (let i = 0; i < this.formBrand.length; i++) {
        const item = this.formBrand[i]
        sum += Number.parseFloat(item.percentTargetNum)
        if (item.percentTargetNum === '') {
          document.getElementById('brandValue-' + i).focus()
          this.$message({
            type: 'warning',
            message: '表单尚未填写完整'
          })
          return
        }
      }

      if (sum !== 100) {
        this.$message({
          type: 'warning',
          message: '品牌级次目标占比相加需为100%'
        })
        return
      }

      params.brandLevels = this.formBrand.map((item, index) => {
        const tempObj = {}
        tempObj.brandLevelType = item.id
        tempObj.brandLevelValue = item.percentTargetNum
        tempObj.brandGradeType = []
        // tempObj.brandGradeValue = item.levelValue.map((ele, i) => {
        //   tempObj.brandGradeType.push(i + 1)
        //   return ele
        // })
        return tempObj
      })
      this.axios.post(api.handleBrandDecisions, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          console.log('resData', resData)
          this.$message({
            type: 'success',
            message: res.data.msg
          })
          this.$emit('refreshData')
          this.handleClose()
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    submitFormFormat () {
      for (let i = 0; i < this.formFormat.length; i++) {
        const item = this.formFormat[i]
        if (item.name === '') {
          this.unfinishedMsg()
          document.getElementById('name-' + item.key).focus()
          return
        }
        if (item.planArea === '') {
          this.unfinishedMsg()
          document.getElementById('planArea-' + item.key).focus()
          return
        }
      }
      const params = {
        decisionId: this.decisionId,
        industryStrategies: [
          // {
          //   areaRate: 0,
          //   industry: 'string',
          //   industryStrategyId: 0,
          //   industryStrategyRents: [
          //     {
          //       decisionStepId: 0,
          //       decisionStepName: 'string',
          //       industryStrategyRentId: 0,
          //       rentFreeTime: 0,
          //       rentIncreasingRate: 0,
          //       standardRent: 0,
          //       stepEndDate: '2021-08-16T07:30:35.479Z',
          //       stepStartDate: '2021-08-16T07:30:35.479Z'
          //     }
          //   ],
          //   managementCost: 0,
          //   planArea: 0,
          //   rentMaxYear: 0,
          //   rentMinYear: 0
          // }
        ],
        rentUnit: this.decisionsInfo.rentUnit // 计租单位(1:元/日/㎡，2:元/月/㎡)
      }
      params.industryStrategies = this.formFormat.map(item => {
        const tempObj = {
          areaRate: item.areaProportion,
          industry: item.name,
          managementCost: item.generalAdministrative,
          planArea: item.planArea,
          rentMaxYear: item.leasingEnd,
          rentMinYear: item.leasingStart
        }
        tempObj.industryStrategyRents = item.rentList.map(ele => {
          const tempObj0 = {
            decisionStepId: ele.decisionStepId,
            decisionStepName: ele.name,
            rentFreeTime: ele.freeTime,
            rentIncreasingRate: ele.rentGrowth || '',
            standardRent: ele.decisionRent
          }
          if (ele.phaseTime && ele.phaseTime.length) {
            tempObj0.stepStartDate = dayjs(ele.phaseTime[0]).format('YYYY/MM/DD')
            tempObj0.stepEndDate = dayjs(ele.phaseTime[1]).format('YYYY/MM/DD')
          }
          if (this.drawerType === 1) {
            tempObj0.industryStrategyRentId = ele.industryStrategyRentId// 更新传id
          }
          return tempObj0
        })
        if (this.drawerType === 1) {
          tempObj.industryStrategyId = item.industryStrategyId // 更新传id
        }
        return tempObj
      })
      console.log('params', params)
      this.axios.post(api.handleFormatDecisionsForm, params).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: res.data.msg
          })
          this.$emit('refreshData')
          this.handleClose()
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    submitFormStore () {
      for (let i = 0; i < this.formStore.length; i++) {
        const item = this.formStore[i]
        if (item.planArea === '') {
          this.unfinishedMsg()
          document.getElementById('store-planArea-' + i).focus()
          return
        }
      }
      // const arg = {
      //   berthStrategies: [ // 租赁策略管理-业态策略表单-业态策略 ,
      //     {
      //       areaRate: 0, // 面积占比
      //       berthStrategyId: 0, // 业态策略ID(新增不传，更新传) ,
      //       berthStrategyRents: [ //  主次力店策略租金 ,
      //         {
      //           berthStrategyRentId: 0, // 主次力店策略租金ID(新增不传，更新传) ,
      //           decisionStepId: 0, // 租赁决策阶段ID(必传) ,
      //           decisionStepName: 'string', // 租赁决策阶段名称 ,
      //           rentFreeTime: 0, // 免租时长(天) ,
      //           rentIncreasingRate: 0, // 租金递增率 ,
      //           standardRent: 0, // 策略租金 ,
      //           stepEndDate: '2021/08/31', // 阶段结束时间 ,
      //           stepStartDate: '2021/08/01' // 阶段开始时间
      //         }
      //       ],
      //       mainBerthType: 0, // 主次力店类型（1：主力店，2：次主力店） ,
      //       managementCost: 0, // 管理费 ,
      //       planArea: 0, // 规划面积 ,
      //       rentMaxYear: 0, // 租赁年限最大值 ,
      //       rentMinYear: 0 // 租赁年限最小值
      //     }
      //   ],
      //   decisionId: 0, // 租赁策略ID(必传) ,
      //   rentUnit: 0 // 计租单位(1:元/日/㎡，2:元/月/㎡)
      // }
      const params = {
        decisionId: this.decisionId,
        berthStrategies: [],
        rentUnit: this.decisionsInfo.rentUnit // 计租单位(1:元/日/㎡，2:元/月/㎡)
      }
      params.berthStrategies = this.formStore.map((item, i) => {
        const tempObj = {
          areaRate: item.areaProportion,
          mainBerthType: i + 1,
          managementCost: item.generalAdministrative,
          planArea: item.planArea,
          rentMaxYear: item.leasingEnd,
          rentMinYear: item.leasingStart
        }
        if (this.drawerType === 1) {
          tempObj.berthStrategyId = item.berthStrategyId
        }
        tempObj.berthStrategyRents = item.rentList.map(ele => {
          const tempObj0 = {
            decisionStepId: ele.decisionStepId,
            decisionStepName: ele.name,
            rentFreeTime: ele.freeTime,
            rentIncreasingRate: ele.rentGrowth || '',
            standardRent: ele.decisionRent
          }
          if (ele.phaseTime && ele.phaseTime.length) {
            tempObj0.stepStartDate = dayjs(ele.phaseTime[0]).format('YYYY/MM/DD')
            tempObj0.stepEndDate = dayjs(ele.phaseTime[1]).format('YYYY/MM/DD')
          }
          if (this.drawerType === 1) {
            tempObj0.berthStrategyRentId = ele.berthStrategyRentId
          }
          return tempObj0
        })
        return tempObj
      })
      this.axios.post(api.handleStoreForm, params).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: res.data.msg
          })
          this.$emit('refreshData')
          this.handleClose()
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    showDrawer (params) {
      this.drawerIndex = params.index
      this.axios.get(api.handleDecisions + this.decisionId, {}).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.decisionsInfo = resData
          this.projectGrossArea = this.decisionsInfo.sumArea
          const { index, type } = params
          // index 品牌0、业态1、主次力店2
          // type 添加0，编辑1
          // this.resetFormFormat()
          // const tempArray = ['S级', 'A级', 'B级', 'C级', 'D级']
          this.boxHeight = 0
          switch (index) {
            case 0:
              if (type) {
                this.axios.get(api.handleBrandDecisions, { params: { decisionId: this.decisionId } }).then(res => {
                  if (res.data.code === 0) {
                    const resData = res.data.data
                    console.log('resData', resData)
                    if (resData.brandLevels && resData.brandLevels.length) {
                      this.brandCardList = resData.brandLevels
                      this.formBrand = this.brandCardList.map(item => {
                        const tempObj = {
                          id: item.brandLevelType,
                          level: item.brandLevelType + '级',
                          percentTargetNum: item.brandLevelValue || 0
                        }
                        return tempObj
                      })
                      this.checkForm(0)
                    }
                  }
                }, rej => {
                  console.log(rej)
                }).catch(err => {
                  console.log(err)
                })
              } else {
                this.formBrand = [
                  {
                    id: 'S',
                    level: 'S级',
                    percentTargetNum: 0
                  },
                  {
                    id: 'A',
                    level: 'A级',
                    percentTargetNum: 0
                  },
                  {
                    id: 'B',
                    level: 'B级',
                    percentTargetNum: 0
                  },
                  {
                    id: 'C',
                    level: 'C级',
                    percentTargetNum: 0
                  },
                  {
                    id: 'D',
                    level: 'D级',
                    percentTargetNum: 0
                  }
                ]
              }
              break
            case 1:
              this.resetFormFormat()
              this.getDataFormatList()
              if (type === 1) {
                this.getDataFormatForm()
              } else {
                this.$nextTick(() => {
                  this.boxHeight = this.$refs.formatContentBox[0].clientHeight
                })
              }
              break
            case 2:
              this.resetFormStore()
              if (type === 1) {
                this.getDataStoreForm()
              } else {
                this.$nextTick(() => {
                  this.boxHeight = this.$refs.storeContentBox[0].clientHeight
                })
              }
              break
            default:
              break
          }
          this.drawerIndex = index
          this.drawerType = type
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataBrand () {
      const params = {
        decisionId: this.decisionId
      }
      this.axios.get(api.handleBrandDecisions, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          console.log('resData', resData)
          if (resData.brandLevels && resData.brandLevels.length) {
            this.hasDataBrand = true
            this.brandCardList = resData.brandLevels
          } else {
            this.hasDataBrand = false
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleClose () {
      this.drawerStrategy = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.strategy-form
  color #fff
  .brand-decision-form
    span
      color #b2b3bd
    ul
      font-size 14px
      &>li
        display inline-block
        margin-bottom 24px
        &:nth-child(2n+1)
          margin-right 24px
        &:last-child, li:nth-last-child(2)
          margin-bottom 0
      &>ul
        background #1E1E23
        border-radius 2px
        padding 24px
        margin-bottom 24px
        li
          margin-right 24px
          span
            margin-right 12px
          div
            width 285px
          &:nth-last-child(2), &:last-child
            margin-bottom 0
  .format-decision-form
    font-size 14px
    .form-item
      overflow hidden
      border-radius 2px
      margin-bottom 24px
      span
        color #b2b3bd
      .item-header
        height 84px
        line-height 84px
        padding 0 24px
        background #1E1E23
        position relative
        z-index 1
        .native-input-solid
          line-height 24px
        .button-s-del, .button-s-add
          top 12px
          margin-left 16px
      .item-content
        transition margin 0.5s ease-in-out
        background #1E1E23
        border-top 1px solid #373A43
        padding 24px
        position relative
        z-index 0
        h3
          margin-bottom 16px
        ul.item-v1
          margin-left 50px
        ul
          margin-left 24px
          li
            display inline-block
            margin-bottom 24px
            &:nth-child(2n+1)
              margin-right 24px
            &:last-child, li:nth-last-child(2)
              margin-bottom 0
        ul.child-list
          width 760px
          height 204px
          padding 24px
          box-sizing border-box
          border 1px solid #373A43
          border-radius 2px
          margin-bottom 24px
          &:last-child
            margin-bottom 0
      .hide-top
        margin-top calc(-150% + 24px)
    .icon-show-hide
      transform rotate(180deg)
      transition transform 0.3s ease-in-out
    .deg0
      transform rotate(0deg)
</style>
