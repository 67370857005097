<template>
  <div
    class="leasing-decision"
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
  >
    <div
      v-if="exportLoading"
      v-loading="exportLoading"
      class="full-loading"
      element-loading-text="导出中..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    ></div>
    <div class="w-crumbs-href fl">
      <!-- <span>您现在所在的位置：</span> -->
      <router-link
        tag="span"
        class="link-item"
        :to="{ path: '/' }"
      >工作台</router-link>
      <i class="el-icon-arrow-right"></i>
      <router-link
        tag="span"
        class="link-item"
        :to="{ path: '/work' }"
      >招商规划管理</router-link>
      <i class="el-icon-arrow-right"></i>
      <span class="w-white">{{$route.meta.title}}</span>
    </div>
    <div
      v-if="hasRight"
      class="filter-panel fr"
    >
      <div v-if="$route.name !== 'detachingShops'">
        <el-select
          class="date-picker-sty"
          popper-class="w-block-select-down yellow-select-panel"
          v-model="filtroProject"
          size="small"
          placeholder="请选择"
          filterable
          style="width: 260px;"
          @change="changeProject(1)"
        >
          <el-option
            v-for="item in projectOptions"
            :key="item.id"
            :label="item.projectName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="clearfix"></div>
    <div
      class="fl sidebar"
      :class="{'fixed-left': isFixedSidebar}"
      :style="'height:9999px'"
      ref="sidebar"
      v-if="$route.meta.title !== '铺位拆合管理'"
    >
      <div class="logo-box main-project">
        <img
          v-if="projectImg"
          :src="projectImg"
          alt=""
        >
      </div>
      <h3>{{projectName || '-'}}</h3>
      <div class="decisions-select">
        <el-select
          ref="projectSelector"
          class="date-picker-sty"
          popper-class="w-block-select-down yellow-select-panel"
          v-model="decisionId"
          filterable
          size="small"
          :title="decisionName.length>12?decisionName:''"
          :placeholder="decisionId===null?'未设置租赁决策文件':''"
          @change="changeDecisionFile"
        >
          <el-option
            v-for="item in leasingDecisionList"
            :key="item.decisionId"
            :title="item.decisionName.length>12?item.decisionName:''"
            :label="item.decisionName.length>12?item.decisionName.slice(0,12)+'...':item.decisionName"
            :value="item.decisionId"
          >
          </el-option>
        </el-select>
      </div>
      <ul class="data-list">
        <li>
          应用楼宇：
          <span
            class="ellipsis"
            :title="leasingDecisionObj.buildingNames"
          >{{leasingDecisionObj.buildingNames || '-'}}</span>
        </li>
        <li class="ellipsis">生效时间：<span>{{leasingDecisionObj.effectStartDate || '-'}}</span></li>
        <li class="ellipsis"><span>~</span></li>
        <li class="ellipsis"><span>{{leasingDecisionObj.effectEndDate || '-'}}</span></li>
        <li class="ellipsis">创建人：<span>{{leasingDecisionObj.createUserName || '-'}}</span></li>
        <li class="ellipsis">创建时间：<span>{{leasingDecisionObj.createTime || '-'}}</span></li>
      </ul>
      <div class="clearfix"></div>
      <ul class="nav-list">
        <router-link
          tag="li"
          :class="{
            'disabled-sty': leasingDecisionList.length===0 || !item.disabled
          }"
          v-for="(item) in navList"
          :key="item.value"
          :to="{
            name: item.value
          }"
        >
          <i :class="item.icon"></i>
          {{item.label}}
        </router-link>
      </ul>
      <div class="handle-panel">
        <ul>
          <li @click="handleDrawer(0)">添加</li>
          <li
            :class="{'disabled-sty': leasingDecisionList.length===0}"
            @click="handleDrawer(1)"
          >编辑</li>
          <li :class="{'disabled-sty': !decisionId}">
            更多
            <i class="el-icon-arrow-up"></i>
            <!-- <i class="el-icon-arrow-down"></i> -->
            <ul class="more-list">
              <li
                :class="{'disabled-sty': leasingDecisionList.length < 2}"
                @click="toDo('contrast')"
              >对比</li>
              <!-- <li>复制</li> -->
              <li
                @click="toDo('del')"
                :class="{'disabled-sty': leasingDecisionList.length===0}"
              >删除</li>
              <li
                @click="toDo('leadingout')"
                :class="{'disabled-sty': leasingDecisionList.length===0}"
              >导出</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="fr main-panel"
      :style="{'width': $route.meta.title === '铺位拆合管理' ? 'calc(100% - 24px)': ''}"
    >
      <transition
        name="fade-transform"
        mode="out-in"
      >
        <router-view
          v-if="decisionId && hasPermission"
          :key="key"
          :projectId='filtroProject'
          :decisionId.sync='decisionId'
          :projectName='projectName'
          :decisionName="decisionName"
          :exportLoading.sync="exportLoading"
          @handleDrawer="handleDrawer"
          @showDrawer="showDrawer"
          @refreshData="refreshData"
        ></router-view>
        <div
          class="no-recommend-brand"
          style="margin-top:200px"
          v-if="decisionId===null"
        >
          <img
            style="margin-top: 40px;"
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>当前项目不存在租赁决策文件</p>
          <div class="button-check-ew mt10">
            <span
              class="button-basic"
              @click="handleDrawer(0)"
            >去添加</span>
          </div>
        </div>
        <div
          class="simpleInfoPanel"
          v-if="!hasPermission"
        >
          <div class="jurisdiction-wrap">
            <img
              src="~@/assets/images/Jurisdiction.png"
              alt=""
            >
            <p>暂无权限访问此页，如需开通权限，</p>
            <p>请联系管理员开通</p>
          </div>
        </div>
      </transition>
    </div>
    <leasing-form
      ref="leasingForm"
      v-if="filtroProject && decisionId !== ''"
      :drawerType="drawerType"
      :decisionId='decisionId'
      :filtroProject="filtroProject"
      @changeDecisions="changeDecisions"
    ></leasing-form>
    <strategy-form
      v-if="decisionId"
      :formType="formType"
      :decisionId="decisionId"
      ref="formstrategy"
      @refreshData="refreshData"
    ></strategy-form>
  </div>
</template>

<script>
import leasingForm from './components/handleLeasingForm.vue'
import strategyForm from './components/handleStrategyForm.vue'
import { powerAuth } from '@/utils'
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  name: 'leasingDecision',
  data () {
    return {
      exportLoading: false,
      cHeight: 0,
      isFixedSidebar: false,
      hasPermission: true,
      loading: false,
      drawerType: 0, // 0添加 1编辑
      drawerStrategyArr: [0, 0],
      formType: 0,
      filtroProject: '',
      projectName: '',
      projectImg: '',
      projectOptions: [],
      decisionId: '',
      decisionName: '',
      leasingDecisionObj: '',
      leasingDecisionList: [],
      navList: [
        {
          label: '租赁决策概览',
          id: 210,
          value: 'work',
          icon: 'icon-leasing-overview'
        },
        {
          label: '租赁策略管理',
          id: 211,
          value: 'leasingStrategy',
          icon: 'icon-leasing-strategy'
        },
        {
          label: '租金计划管理',
          id: 212,
          value: 'leasingPlan',
          icon: 'icon-leasing-plan'
        },
        {
          label: '落位计划管理',
          id: 213,
          value: 'planningProject',
          icon: 'icon-landing-plan'
        },
        {
          label: '租赁决策分析',
          id: 209,
          value: 'leasingAnalysis',
          icon: 'icon-leasing-analysis'
        }
      ],
      hasRight: true
    }
  },
  components: { leasingForm, strategyForm },
  watch: {
    exportLoading: (newVal, oldVal) => {
      if (newVal === false) {
        document.body.style.overflowY = 'auto'
      } else {
        document.body.style.overflowY = 'none'
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    key () {
      return this.$route.name
    }
  },
  mounted () {
    // 租赁决策分析 209
    // 租赁决策概览 210
    // 租赁策略管理 211
    // 租金计划管理 212
    // 落位计划管理 213
    for (let i = 0; i < this.navList.length; i++) {
      const item = this.navList[i]
      item.disabled = powerAuth(item.id, 'show')
    }
    for (let i = 0; i < this.navList.length; i++) {
      const item = this.navList[i]
      if (i === 0 && item.disabled) {
        break
      }
      if (item.disabled) {
        this.$router.push({
          name: item.value
        })
      }
    }
    this.getDataProjList()
    this.cHeight = Number(document.documentElement.clientHeight) - 183
    this.loading = true
    setTimeout(() => {
      this.loading = false
    }, 500)
    // this.$nextTick(() => {
    //   this.$refs.sidebar.style.minHeight = document.body.clientHeight - 264 + 'px'
    //   console.log('window.screen.availHeight', document.body.clientHeight)
    // })
    window.addEventListener('scroll', this.setFixedMenu)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.setFixedMenu)
  },
  methods: {
    setFixedMenu (e) {
      // const scrollPosition = document.documentElement.clientHeight - window.pageYOffset
      if (window.pageYOffset > 116) {
        this.isFixedSidebar = true
      } else {
        this.isFixedSidebar = false
      }
    },
    toExport () {
      // const Urlhref = this.$router.resolve({
      //   path: this.$winshangDomain + '/report#/exportPdf/leasingDecisionsPDF',
      //   query: {
      //     id: this.filtroProject,
      //     picType: 1,
      //     decisionId: this.decisionId
      //   }
      // })
      //
      window.open(`/report.html#/exportPdf/leasingDecisionsPDF?id=${this.filtroProject}&picType=1&decisionId=${this.decisionId}`, '_blank')
    },
    refreshData () {
      const tempValue = this.decisionId
      this.decisionId = ''
      this.$nextTick(() => {
        this.decisionId = tempValue
      })
    },
    showDrawer (params) {
      const { index, type } = params
      console.log('index', index, type)
      this.$nextTick(() => {
        this.$refs.formstrategy.drawerStrategy = true
        this.$refs.formstrategy.showDrawer(params)
      })
    },
    delDecisionsFile () {
      this.$confirm('删除后，将清空当前租决文件的所有配置，是否确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose: false,
        customClass: 'message-box'
      }).then(() => {
        this.axios.delete(api.handleDecisions + this.decisionId, {}).then(res => {
          if (res.data.code === 0) {
            const resData = res.data.data
            console.log('resData', resData)
            this.handleData()
          }
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
      })
    },
    handleClose () {
      this.drawerStrategy = false
    },
    getDecisionsList (decisionId) {
      this.axios.get(api.decisions, { params: { projectId: this.filtroProject } }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          console.log('resData', resData)
          if (resData && resData.length) {
            this.leasingDecisionList = resData
            let obj = {}
            if (decisionId) {
              console.log('decisionId', decisionId)
              obj = resData.find(item => {
                return item.decisionId === decisionId
              })
            } else {
              obj = resData.find(item => {
                return item.nearest === 1
              })
            }
            if (!obj) {
              obj = resData[0]
            }
            if (obj) {
              this.decisionId = obj.decisionId
              this.decisionName = obj.decisionName
              this.leasingDecisionObj = obj
              this.leasingDecisionObj.effectDate = obj.effectStartDate + '-' + obj.effectEndDate
            } else {
              this.leasingDecisionList = []
              this.decisionId = null
            }
          } else {
            this.leasingDecisionList = []
            this.decisionId = null
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleDrawer (key) {
      this.toDo(['add', 'edit'][key], key)
    },
    checkPowerAuth (keyword) {
      if (!powerAuth(210, keyword)) {
        this.$message({
          type: 'warning',
          message: '暂无权限访问此功能，如需开通权限，请联系管理员开通'
        })
        return false
      }
      return true
    },
    toDo (keyword, key) {
      if (this.leasingDecisionList.length === 1 && keyword === 'contrast') {
        return
      }
      const flag = this.checkPowerAuth(keyword)
      if (!flag) {
        return
      }
      switch (keyword) {
        case 'add':
        case 'edit':
          this.$refs.leasingForm.drawer = true
          this.$refs.leasingForm.drawerType = key
          this.$refs.leasingForm.getBuildingFloor()
          this.$refs.leasingForm.getCostItem()
          this.$refs.leasingForm.resetData()
          break
        case 'contrast':
          this.$router.push({ path: '/leasingComparison?decision=' + this.decisionId + '&project=' + this.filtroProject })
          break
        case 'del':
          this.delDecisionsFile()
          break
        case 'leadingout':
          this.toExport()
          break
        default:
          break
      }
    },
    changeProject (key) {
      this.leasingDecisionObj = {}
      this.leasingDecision = ''
      this.getDecisionsList()
      this.updateProjectIdFunc()
      const obj = this.projectOptions.find(item => {
        return item.id === this.filtroProject
      })
      this.projectName = obj.projectName
      this.projectImg = obj.projectLogo
      if (this.$route.name === 'work' || !key) {
        // this.handleData()
      } else {
        this.$router.push({
          name: 'work'
        })
      }
    },
    changeDecisionFile (val) {
      this.leasingDecisionObj = {}
      this.leasingDecision = ''
      this.decisionId = ''
      console.log('val', val)
      console.log('this.leasingDecisionList', this.leasingDecisionList)
      const obj = this.leasingDecisionList.find(item => {
        return item.decisionId === val
      })
      console.log('obj', obj)
      setTimeout(() => {
        this.$refs.projectSelector.blur()
        if (obj) {
          this.decisionName = obj.decisionName
          this.decisionId = obj.decisionId
          this.decisionName = obj.decisionName
          this.leasingDecisionObj = obj
          this.leasingDecisionObj.effectDate = obj.effectStartDate + '-' + obj.effectEndDate
        }
      }, 200)
    },
    updateProjectIdFunc () {
      const params = {
        projectId: this.filtroProject
      }
      this.axios.post(api.updateProjectId, params)
        .then((res) => {
        })
    },
    getProjectIdFunc () {
      return new Promise((resolve, reject) => {
        this.axios.get(api.getProjectId)
          .then((res) => {
            if (res.status === 200) {
              resolve(res)
            } else {
              reject(new Error(''))
            }
          })
      })
    },
    changeDecisions (decisionId) {
      this.decisionId = ''
      this.leasingDecisionObj = {}
      this.leasingDecision = ''
      this.getDecisionsList(decisionId)
    },
    handleData () {
      this.leasingDecisionObj = {}
      this.leasingDecision = ''
      this.decisionId = ''
      this.getDecisionsList()
      this.updateProjectIdFunc()
      this.changeProject()
    },
    getDataProjList () { // select项目列表
      this.axios.post(api.getBerthProjectList, {
      }).then(res => {
        if (res.data.code === 0) {
          this.projectOptions = res.data.data
          if (this.projectOptions.length > 0) {
            // this.filtroProject = this.projectOptions[0].id // 默认选中第一个项目
            // this.projectName = this.projectOptions[0].projectName // 默认选中第一个项目
            // this.$router.push({
            //   query: Object.assign({ projectId: this.filtroProject }, this.$route.query, { _: +new Date() })
            // })
            this.getProjectIdFunc().then((res) => {
              this.filtroProject = res.data.data.projectId
              this.projectName = res.data.data.projectName
              const obj = this.projectOptions.find(item => {
                return item.id === this.filtroProject
              })
              this.projectImg = obj.projectLogo
              this.handleData()
            })
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.leasing-decision
  // color #fff
  .w-crumbs-href, .filter-panel
    margin 12px 24px
  .fixed-left
    position fixed !important
    height 9999px
    top 0
    left 0
    z-index 999
    transition height 0.3s
  .sidebar
    width 250px
    height 100%
    background #272930
    position relative
    color rgba(128, 129, 145, 1)
    padding-bottom 69px
    transition height 0.3s
    .logo-box
      width 96px
      height 80px
      margin 16px auto 14px auto
      position relative
      img
        width 96px
        height 80px
        border-radius 2px
        object-fit cover
        border 1px solid #424752
  .handle-panel
    position fixed
    z-index 999
    bottom 0px
    background #272930
    width 250px
    height 68px
    line-height 68px
    text-align center
    font-size 14px
    border-top 1px solid #424752
    li
      color rgba(255, 255, 255, 0.3)
      cursor pointer
      display inline-block
      width 33.3%
      color #fff
      &:after
        content ''
        height 14px
        width 1px
        background #424752
        display inline-block
        position relative
        left 33px
        top 2px
      &:last-child:after
        display none
      &:hover
        color rgba(255, 255, 255, 1)
      &:last-child:hover
        .more-list
          display block
    .more-list
      display none
      position absolute
      top -130px
      background #272930
      border 1px solid #424752
      box-shadow 0px 3px 20px rgba(0, 0, 0, 0.6)
      border-radius 2px
      li
        display block
        width 100px
        height 44px
        line-height 44px
        &:after
          display none
        &:hover
          background rgba(255, 255, 255, 0.05)
  h3
    text-align center
    color #fff
  .decisions-select>>>
    width 202px !important
    margin 8px auto 0 auto
    .date-picker-sty
      width 202px !important
      .el-input
        width 202px !important
  .data-list
    margin 12px 24px 24px 24px
    overflow hidden
    font-size 14px
    li
      height 22px
      line-height 22px
      font-size 12px
      span
        float right
        display inline-block
        max-width 165px
        color #fff
  .nav-list
    border-top 1px solid #424752
    padding 17px 0
    overflow hidden
    li
      height 44px
      line-height 44px
      cursor pointer
      margin 7px 0
      font-size 14px
      i
        margin 0 10px 0 24px
      &:hover
        color #fff
        i
          color #fff
    .router-link-active
      color #fff
      background #33343A
      border-left 3px solid #ffa134
      i
        color #fff
.main-panel
  min-height 888px
  width calc(100% - 300px)
  margin 0 24px
</style>
