<template>
  <el-drawer
    title=""
    class="black-drawer"
    :size="'895px'"
    :visible="drawer"
    :before-close="handleClose"
    :with-header="false"
  >
    <div class="drawer-header">
      {{drawerType?'编辑':'添加'}}
      <i
        class="el-icon-close fr pointer"
        @click="handleClose"
      ></i>
    </div>
    <div
      class="drawer-wrap is-scroll-y"
      @click="clickFormBody"
      :style="'height:'+wrapHeight"
    >
      <div class="handle-leasing-form">
        <h3 class="w-title-border-left">基础信息</h3>
        <ul class="form-list shadow-box">
          <li>
            <span class="requiredFields">决策名称</span>
            <div class="native-input-solid">
              <input
                id="formName"
                @input="limitLength(0, 30)"
                @focus="notYet=true"
                @blur="hasFullBasicSet"
                type="text"
                v-model="form.name"
                autocomplete="off"
                placeholder="请输入"
              />
            </div>
          </li>
          <li>
            <span class="requiredFields">楼宇范围</span>
            <el-select
              class="date-picker-sty"
              popper-class="w-block-select-down yellow-select-panel"
              filterable
              size="small"
              placeholder="请选择"
              v-model="form.buildingScope"
              @blur="hasFullBasicSet"
              @change="hasFullBasicSet"
              id="buildingScope"
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in optionsBuilding"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
          <li>
            <span class="requiredFields">生效时间</span>
            <el-date-picker
              :clearable="false"
              class="date-picker-sty"
              popper-class="black-el-date-picker"
              type="daterange"
              range-separator="-"
              v-model="form.effectTime"
              id="effectTime"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
              @focus="notYet=true"
              @blur="hasFullBasicSet"
              @change="handlePickerOptions"
              placeholder="选择日期"
            >
            </el-date-picker>
          </li>
          <li>
            <span class="requiredFields">计租单位</span>
            <el-select
              class="date-picker-sty"
              :class="{'disabled-sty': drawerType===1}"
              popper-class="w-block-select-down yellow-select-panel"
              filterable
              size="small"
              placeholder="请选择"
              @focus="notYet=true"
              v-model="form.leasingUnit"
              @change="hasFullBasicSet"
              id="leasingUnit"
              :disabled="drawerType===1"
            >
              <el-option
                v-for="item in optionsUnit"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-tooltip
              style="margin-left: 8px"
              class="item"
              effect="dark"
              content="计租单位将应用于当前租赁决策文件所有计价逻辑中，选择后不可更改"
              placement="top-end"
            >
              <span>
                <i class="icon-help-tips"></i>
              </span>
            </el-tooltip>
          </li>
        </ul>
        <h3
          class="form-item-title"
          v-show="showPhasePanel"
        >
          阶段设置
          <el-tooltip
            class="item"
            effect="dark"
            content="阶段范围将应用于计算阶段目标、界定租金递增节点（如有）。"
            placement="right-end"
          >
            <span>
              <i class="icon-help-tips"></i>
            </span>
          </el-tooltip>
        </h3>
        <ul
          class="form-list shadow-box steps-set"
          v-show="showPhasePanel"
        >
          <template v-for="(item,index) in form.phaseList">
            <li :key="index+'-1'">
              <span class="requiredFields">阶段名称</span>
              <div class="native-input-solid">
                <input
                  @input="limitLength(index, 12)"
                  @focus="notYet=true"
                  @blur="hasFullBasicSet"
                  type="text"
                  v-model="item.phaseName"
                  :id="'phaseName-'+index"
                  autocomplete="off"
                  placeholder="请输入"
                />
              </div>
            </li>
            <li :key="index+'-2'">
              <span class="requiredFields">阶段时间</span>
              <el-date-picker
                v-if="datePickerLimit && datePickerLimit.length"
                :clearable="false"
                class="date-picker-sty"
                popper-class="black-el-date-picker"
                @focus="notYet=true"
                @blur="hasFullBasicSet"
                type="daterange"
                range-separator="-"
                :ref="'phaseTime-'+index"
                v-model="item.phaseTime"
                placeholder="选择日期"
                :picker-options="pickerOptions"
                format="yyyy/MM/dd"
                value-format="yyyy/MM/dd"
              >
              </el-date-picker>
            </li>
            <div
              :key="index+'-5'"
              class="handle-btn"
              v-if="index === form.phaseList.length -1 "
            >
              <span
                :key="index+'-3'"
                class="button-s-add"
                @click="handlePhaseList('add', index)"
              ></span>
              <span
                :key="index+'-4'"
                :class="{'disabled-sty': form.phaseList.length===1}"
                class="button-s-del"
                @click="handlePhaseList('del', index)"
              ></span>
            </div>
          </template>
        </ul>
        <h3
          class="form-item-title"
          v-show="showPhasePanel"
        >
          费用统计设置
          <el-tooltip
            class="item"
            effect="dark"
            :content="`设置后，对应费用项所产生的费用将纳入租金或${user.topuid === 6467 ? '物业' : '综合'}管理费的统计`"
            placement="right-end"
          >
            <span>
              <i class="icon-help-tips"></i>
            </span>
          </el-tooltip>
        </h3>
        <div
          class="shadow-box"
          v-show="showPhasePanel"
        >
          <ul
            class="border-box"
            v-for="(item,index) in form.countSetList"
            :key="index"
          >
            <li>
              <span class="requiredFields">租决统计项</span>
              <div class="native-input-solid disabled-sty">
                <input
                  type="text"
                  disabled
                  v-model="item.name"
                  placeholder="请输入"
                />
              </div>
            </li>
            <li>
              <span class="requiredFields">关联费用项</span>
              <span
                class="text-span"
                v-for="(item,i) in item.relatedItem"
                :key="i"
              >
                {{item && item.split('&')[1]}}
                <i
                  class="el-icon-close"
                  @click="delItem(index, i)"
                ></i>
              </span>
              <el-popover
                popper-class="black-popover"
                placement="top"
                v-model="showPopover[index]"
                trigger="manual"
              >
                <h3>添加费用项目</h3>
                <el-select
                  class="date-picker-sty"
                  popper-class="w-block-select-down yellow-select-panel"
                  filterable
                  size="small"
                  v-model="countSetItem"
                  :change="checkForm()"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in optionsCountSet"
                    :key="item.costItemCode"
                    :label="item.costItemName"
                    :value="item.costItemCode+'&'+item.costItemName"
                  ></el-option>
                </el-select>
                <div class="footer">
                  <span
                    class="button-basic button-primary"
                    :class="{'disabled-sty': countSetItem===''}"
                    @click="handleCountSetList(index)"
                  >确定</span>
                  <span
                    class="button-basic button-low"
                    @click.stop="handleCountSetItem(0, index)"
                  >取消</span>
                </div>
                <span
                  slot="reference"
                  class="button-s-add"
                  @click.stop="handleCountSetItem(1, index)"
                ></span>
              </el-popover>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="drawer-footer">
      <span
        @click="checkFormBeforeSubmit"
        class="button-basic button-primary"
        :class="{'disabled-sty':notYet}"
      >保存</span>
      <span
        @click="handleClose"
        class="button-basic button-low"
      >取消</span>
    </div>
  </el-drawer>
</template>

<script>
import { debounce } from '@/utils'
import { mapGetters } from 'vuex'
import api from '@/api'
import dayjs from 'dayjs'
export default {
  name: 'handleLeasingForm',
  data () {
    return {
      formBodyNoClick: true,
      notYet: true,
      drawerType: 0,
      drawer: false,
      form: {
        name: '',
        buildingScope: [],
        effectTime: '',
        leasingUnit: '',
        phaseList: [
          {
            phaseName: '',
            phaseTime: []
          }
        ],
        countSetList: [
          {
            name: '租金',
            relatedItem: []
          },
          {
            name: '综合管理费',
            relatedItem: []
          }
        ]
      },
      optionsBuilding: [
        {
          label: '1',
          value: 1
        }
      ],
      optionsUnit: [
        {
          label: '元/日/㎡',
          value: 1
        },
        {
          label: '元/月/㎡',
          value: 2
        }
      ],
      optionsCountSet: [],
      showPopover: [false, false],
      showPhasePanel: false,
      datePickerLimit: [],
      pickerOptions: {
        disabledDate: (time) => {
          const startDate = new Date(this.datePickerLimit[0])
          const endDate = new Date(this.datePickerLimit[1])
          return time < startDate || time > endDate
        }
      },
      countSetItem: ''
    }
  },
  props: {
    filtroProject: {
      type: Number,
      default: 0
    },
    decisionId: {
      type: Number,
      default: 0
    }
  },
  watch: {
    drawerType (newValue, oldValue) {
    }
  },
  mounted () {
    this.resetData()
    console.log('fotm', this.form)
  },
  computed: {
    wrapHeight: () => {
      return document.documentElement.clientHeight - 145 + 'px'
    },
    ...mapGetters({
      user: 'user'
    })
  },
  methods: {
    checkForm () {
      if (this.form.name === '') {
        this.notYet = true
        return false
      }
      if (!this.form.buildingScope.length) {
        this.notYet = true
        return false
      }
      if (this.form.effectTime === '') {
        this.notYet = true
        return false
      }
      if (this.form.leasingUnit === '') {
        this.notYet = true
        return false
      }
      for (let i = 0; i < this.form.phaseList.length; i++) {
        const item = this.form.phaseList[i]
        if (item.phaseName === '') {
          this.notYet = true
          return false
        }
        if (item.phaseTime && item.phaseTime.length === 0) {
          this.notYet = true
          return false
        }
      }
      for (let i = 0; i < this.form.countSetList.length; i++) {
        const item = this.form.countSetList[i]
        if (item.relatedItem && item.relatedItem.length === 0) {
          this.notYet = true
          return false
        }
      }
      this.notYet = false
    },
    handlePickerOptions (data) {
      console.log('data', data)
      this.datePickerLimit = data
    },
    handleClose () {
      this.drawer = false
      for (let i = 0; i < this.showPopover.length; i++) {
        this.$set(this.showPopover, i, false)
      }
      this.resetData()
    },
    resetData () {
      this.showPhasePanel = false
      this.notYet = true
      this.form = {
        name: '',
        buildingScope: [],
        effectTime: '',
        leasingUnit: '',
        phaseList: [
          {
            phaseName: '',
            phaseTime: []
          }
        ],
        countSetList: [
          {
            name: '租金',
            relatedItem: []
          },
          {
            name: '综合管理费',
            relatedItem: []
          }
        ]
      }
      if (this.drawerType === 1) {
        console.log('decisionId', this.decisionId)
        this.form.decisionId = this.decisionId
        this.getDecision()
        this.showPhasePanel = true
      }
    },
    getDecision () {
      this.axios.get(api.handleDecisions + this.decisionId, {}).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.form.leasingUnit = resData.rentUnit
          this.form.name = resData.decisionName
          this.form.decisionId = resData.decisionId
          this.form.effectTime = [dayjs(resData.effectStartDate).format('YYYY/MM/DD'), dayjs(resData.effectEndDate).format('YYYY/MM/DD')]
          this.datePickerLimit = [dayjs(resData.effectStartDate).format('YYYY/MM/DD'), dayjs(resData.effectEndDate).format('YYYY/MM/DD')]
          if (resData.buildingCodes && resData.buildingCodes.length) {
            this.form.buildingScope = []
            this.form.buildingScope = resData.buildingCodes
            console.log('this.form.buildingScope', this.form.buildingScope)
          }
          this.form.phaseList = resData.steps.map(item => {
            const tempObj = {}
            tempObj.decisionStepId = item.decisionStepId
            tempObj.phaseName = item.decisionStepName
            tempObj.phaseTime = [item.stepStartDate, item.stepEndDate]
            return tempObj
          })
          this.form.countSetList = resData.costStatisticsSets.map(item => {
            const tempObj = {}
            tempObj.type = item.costStatisticsSetType
            tempObj.name = item.costStatisticsSetType === 1 ? '租金' : '综合管理费'
            tempObj.relatedItem = item.costItemName.map((ele, index) => {
              const tempStr = item.costItemCode[index] + '&' + ele
              return tempStr
            })
            return tempObj
          })
          this.checkForm()
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getCostItem () {
      const params = {
        projectId: this.filtroProject,
        pageSize: 100,
        pageNum: 1
      }
      this.axios.get(api.costItem, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.optionsCountSet = resData.dataList
          console.log('resData', resData)
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getBuildingFloor () {
      const params = {
        projectId: this.filtroProject
      }
      this.axios.post(api.getGdBuildingFloor, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.optionsBuilding = resData
          if (resData && resData.length === 1 && this.drawerType === 1) {
            this.form.buildingScope = []
            this.form.buildingScope = resData
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    checkFormBeforeSubmit () {
      if (this.form.name === '') {
        this.$message({
          type: 'warning',
          message: '决策名称尚未填写'
        })
        document.getElementById('formName').focus()
        return false
      }
      if (this.form.buildingScope.length === 0) {
        this.$message({
          type: 'warning',
          message: '楼宇范围尚未选择'
        })
        document.getElementById('buildingScope').focus()
        return false
      }
      if (this.form.effectTime === '') {
        this.$message({
          type: 'warning',
          message: '楼宇范围尚未选择'
        })
        document.getElementById('effectTime').focus()
        return false
      }
      if (this.form.leasingUnit === '') {
        this.$message({
          type: 'warning',
          message: '计租单位尚未选择'
        })
        document.getElementById('leasingUnit').focus()
        return false
      }
      for (let i = 0; i < this.form.phaseList.length; i++) {
        const item = this.form.phaseList[i]
        if (item.phaseName === '') {
          this.$message({
            type: 'warning',
            message: '阶段名称尚未选择'
          })
          document.getElementById('phaseName-' + i).focus()
          return false
        }
        if (!item.phaseTime) {
          this.$message({
            type: 'warning',
            message: '阶段时间尚未选择'
          })
          if (this.$refs['phaseTime-' + i]) {
            this.$refs['phaseTime-' + i][0].focus()
          }
          return false
        }
      }
      for (let i = 0; i < this.form.countSetList.length; i++) {
        const item = this.form.countSetList[i]
        if (item.relatedItem && item.relatedItem.length === 0) {
          this.$message({
            type: 'warning',
            message: '关联费用项尚未添加'
          })
          return false
        }
      }
      this.submitForm(this.form)
    },
    submitForm (form) {
      let apiUrl = 'addDecisions'
      if (this.drawerType) {
        apiUrl = 'updateDecisions'
      }
      const params = {
        buildingCodes: form.buildingScope,
        decisionName: form.name,
        projectId: this.filtroProject,
        rentUnit: form.leasingUnit
      }
      params.costStatisticsSets = form.countSetList.map(item => {
        const temObj = {}
        temObj.costStatisticsSetType = item.name === '租金' ? 1 : 2
        temObj.costItemCode = []
        temObj.costItemName = []
        for (let i = 0; i < item.relatedItem.length; i++) {
          const ele = item.relatedItem[i]
          temObj.costItemCode.push(ele.split('&')[0])
          temObj.costItemName.push(ele.split('&')[1])
        }
        return temObj
      })
      form.decisionId && (params.decisionId = form.decisionId)
      form.effectTime && form.effectTime.length && (params.effectStartDate = dayjs(form.effectTime[0]).format('YYYY/MM/DD'))
      form.effectTime && form.effectTime.length && (params.effectEndDate = dayjs(form.effectTime[1]).format('YYYY/MM/DD'))
      params.steps = form.phaseList.map(item => {
        const temObj = {}
        this.drawerType && item.decisionStepId && (temObj.decisionStepId = item.decisionStepId)
        temObj.decisionStepName = item.phaseName
        temObj.stepStartDate = dayjs(item.phaseTime[0]).format('YYYY/MM/DD')
        temObj.stepEndDate = dayjs(item.phaseTime[1]).format('YYYY/MM/DD')
        return temObj
      })
      this.axios.post(api[apiUrl], params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.$message({
            type: 'success',
            message: res.data.msg
          })
          console.log('resData', resData)
          this.$emit('changeDecisions', this.decisionId)
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    hasFullBasicSet () {
      if (
        this.form.name &&
        this.form.buildingScope &&
        this.form.buildingScope.length &&
        this.form.effectTime &&
        this.form.leasingUnit
      ) {
        this.showPhasePanel = true
      } else {
        this.showPhasePanel = false
      }
      this.checkForm()
    },
    limitLength: debounce(function (index, num) {
      let value = ''
      if (num === 30) {
        value = this.form.name
      } else {
        value = this.form.phaseList[index].phaseName
      }
      if (value.length > num) {
        if (num === 30) {
          this.form.name = value.substring(0, num)
        } else {
          this.form.phaseList[index].phaseName = value.substring(0, num)
        }
        value = ''
        this.$message({
          type: 'warning',
          message: '请输入不超过' + num + '个字符'
        })
      }
    }, 300),
    delItem (index, i) {
      this.notYet = true
      this.form.countSetList[index].relatedItem.splice(i, 1)
      this.checkForm()
    },
    clickFormBody () {
      console.log('?')
      if (!this.formBodyNoClick) {
        for (let i = 0; i < this.showPopover.length; i++) {
          this.$set(this.showPopover, i, false)
        }
        this.formBodyNoClick = true
      }
    },
    handleCountSetList (index) {
      console.log('countSetItem', this.countSetItem)
      if (this.countSetItem) {
        this.form.countSetList[index].relatedItem.push(this.countSetItem)
        this.countSetItem = ''
        this.showPopover[index] = false
      }
    },
    handleCountSetItem (key, index) {
      if (key) {
        this.clickFormBody()
        this.$set(this.showPopover, index, true)
        this.formBodyNoClick = false
      } else {
        this.$set(this.showPopover, index, false)
      }
    },
    handlePhaseList (method, index) {
      this.notYet = true
      if (method === 'add') {
        if (
          this.form.phaseList[index].phaseName &&
          this.form.phaseList[index].phaseTime &&
          this.form.phaseList[index].phaseTime.length
        ) {
          this.form.phaseList.splice(index + 1, 0, {
            phaseName: '',
            phaseTime: ''
          })
        } else {
          this.$message({
            type: 'warning',
            message: '请完善当前阶段信息'
          })
        }
      }
      if (method === 'del') {
        this.form.phaseList.splice(index, 1)
      }
      this.checkForm()
    }
  }
}
</script>

<style lang="stylus" scoped>
.handle-leasing-form>>>
  overflow hidden
  .el-range-input
    width 39% !important
  .el-range-separator
    color #fff
  .el-tooltip
    margin-left 4px
  h3.form-item-title
    background #1E1E23
    height 53px
    line-height 53px
    border-bottom 1px solid #373A43
    padding-bottom 24px
    padding-left 24px
    box-sizing border-box
    font-size 14px
  .shadow-box
    background #1E1E23
    border-radius 2px
    padding 24px 24px 0 24px
    overflow hidden
  .border-box
    border 1px solid #373A43
    border-radius 2px
    padding 16px
    margin-bottom 24px
    font-size 14px
    span.requiredFields
      color #B2B3BD
    li
      span
        vertical-align middle
    li:first-child
      margin-bottom 18px
  .steps-set
    position relative
    .handle-btn
      position absolute
      right 40px
      bottom 20px
  .form-list
    overflow hidden
    margin-bottom 24px
    font-size 14px
    span
      color #B2B3BD
    .button-s-add
      margin-right 12px
    li
      display inline-block
      margin-bottom 24px
    li:nth-child(2n+1)
      margin-right 24px
  .shadow-title
    background #1E1E23
    padding 16px 24px
  .text-span
    display inline-block
    height 36px
    box-sizing border-box
    margin 7px
    background #373A43
    border-radius 2px
    padding 8px 29px 9px 9px
    margin-right 16px
    position relative
    .el-icon-close
      cursor pointer
      position absolute
      right 7px
      top 12px
</style>
